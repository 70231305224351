<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { getSessionStorage } from "@/utils/storage";
import { includes } from "lodash";
import { isSuperAdmin, isAdmin } from "utils/index";

export default {
  data() {
    return {
      activeIndex: "/setting/tableTent",
      menuList: [],
      routeList: [
        // {
        //   index: "/setting/payment",
        //   title: this.$t("paymentManagement")
        // }
        // {
        //   index: "/setting/shop",
        //   title: this.$t("storeManagement")
        // },
        // {
        //   index: "/setting/user",
        //   title: this.$t("userManagement")
        // },
        // {
        //   index: "/setting/rights",
        //   title: this.$t("userRight")
        // }
      ]
    };
  },
  mounted() {
    // console.log("mounted");
    // console.log("isAdmin", isAdmin());
    // this.initData();
  },
  methods: {
    initData() {
      this.routeList = new Array();

      const userInfo = JSON.parse(getSessionStorage("userInfo"));

      this.menuList = userInfo.sysModulePermisson.map(item => {
        return item.modelName;
      });

      if (this.isAdmin) {
        if (includes(this.menuList, "Ent")) {
          this.routeList.push({
            index: "/setting/adminUser",
            title: this.$t("merchantManagement")
          });
          this.activeIndex = "/setting/adminUser";
          const userInfo = JSON.parse(getSessionStorage("userInfo"));
          if (userInfo.roleName === "SuperAdmin") {
            this.routeList.push({
              index: "/setting/adminUserManage",
              title: this.$t("userPageTitle")
            });
            this.routeList.push({
              index: "/setting/adminUserRole",
              title: this.$t("userRight")
            });
          }

          //         {
          //   path: "adminUserRole",
          //   component: () => import("@/pages/Setting/AdminUserRole.vue"),
          //   name: "adminUserRole",
          //   meta: {
          //     title: i18n.tc("userRight")
          //   }
          // },
          // {
          //   path: "adminUserManage",
          //   component: () => import("@/pages/Setting/AdminUserManage.vue"),
          //   name: "adminUserManage",
          //   meta: {
          //     title: i18n.tc("userPageTitle")
          //   }
          // }
        }
      } else {
        // this.routeList.push({
        //   index: "/setting/payment",
        //   title: this.$t("paymentManagement")
        // });

        this.routeList.push({
          index: "/setting/tableTent",
          title: this.$t("tableTent")
        });

        if (includes(this.menuList, "Shop")) {
          this.routeList.push({
            index: "/setting/shop",
            title: this.$t("storeManagement")
          });
        }

        if (includes(this.menuList, "User")) {
          this.routeList.push({
            index: "/setting/user",
            title: this.$t("userManagement")
          });
        }

        if (includes(this.menuList, "Permission")) {
          this.routeList.push({
            index: "/setting/rights",
            title: this.$t("userRight")
          });
        }
      }

      if (this.routeList.length > 0 && this.$route.path != this.routeList[0].index) {
        this.$router.push(this.routeList[0].index);
      }
    }
  },
  computed: {
    activeMenu() {
      return this.$route.path;
    },
    isAdmin() {
      return isAdmin();
    },

    isSuperAdmin() {
      return isSuperAdmin();
    }
  },
  watch: {
    // $route(to, from) {
    //   console.log("rchange", to);
    //   if (to.name == "PointsRules") {
    //     this.activeIndex = "/setting/shop";
    //   }
    //   if (to.name == "SetPayment") {
    //     this.initData();
    //   }
    // }
  }
};
</script>
